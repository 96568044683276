import React, { useState } from "react";
import { Header } from "../../Components/Header";
import "../../Styling/ExperiencePage.css";
import { Footer } from "../../Components/Footer";

export function ExperiencePage() {
  const [isF5DropdownOpen, setIsF5DropdownOpen] = useState(false);
  const [isDatasophicalDropdownOpen, setIsDatasophicalDropdownOpen] = useState(false);

  const toggleDropdown = (dropdown) => {
    if (dropdown === "F5") {
      setIsF5DropdownOpen(!isF5DropdownOpen);
      setIsDatasophicalDropdownOpen(false);
    } else {
      setIsDatasophicalDropdownOpen(!isDatasophicalDropdownOpen);
      setIsF5DropdownOpen(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="experiencepage">
        <div className="experiencepage-container">
          <h1 className="page-title-experience">Professional Experience</h1>
          <div className="dropdown-container">
            {/* F5 Networks Section */}
            <div className="dropdown-header" onClick={() => toggleDropdown("F5")}>
              <p className="dropdown-header-role">Software Engineer Intern - F5 Networks</p>
              <p className="dropdown-header-date">2021</p>
              <span className="dropdown-header-icon">
                {isF5DropdownOpen ? "-" : "+"}
              </span>
            </div>
            {isF5DropdownOpen && (
              <div className="dropdown-content">
                <ul className="dropdown-content-list">
                  <li>Optimized fraud detection application’s launch time by 67% using JavaScript and enhanced UI through HTML/CSS improvements.</li>
                  <li>Developed a Python-based AWS Lambda function for real-time IAM user Password Policy alerts, demonstrating proficiency in serverless architecture.</li>
                  <li>Enabled suspicious activity alerts on Slack through SumoLogic integration within F5 Trust, enhancing team security awareness.</li>
                  <li>Completed a 10-week cybersecurity course tailored to F5 Networks, deepening understanding of cybersecurity principles applicable to corporate environments.</li>
                </ul>
                <div className="divider-bar"></div>
                <ul className="skills-footer">
                  <li>JavaScript</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>AWS</li>
                  <li>Python</li>
                  <li>Sumo Logic</li>
                </ul>
              </div>
            )}

            {/* Datasophical Section */}
            <div className="dropdown-header" onClick={() => toggleDropdown("Datasophical")}>
              <p className="dropdown-header-role">Full Stack Engineer - Datasophical</p>
              <p className="dropdown-header-date">2023-2024</p>
              <span className="dropdown-header-icon">
                {isDatasophicalDropdownOpen ? "-" : "+"}
              </span>
            </div>
            {isDatasophicalDropdownOpen && (
              <div className="dropdown-content">
                <ul className="dropdown-content-list">
                  <li>Co-developed DSGuard, an AI-driven chatbot for Cloud/IT cost reduction and cybersecurity, enhancing infrastructure efficiency using Python, Flask, HTML, CSS, and JavaScript.</li>
                  <li>Led UI/UX design with Figma, creating prototypes that informed development, and crafted a detailed project requirements document to streamline the development process.</li>
                  <li>Integrated OpenAI's GPT-4 for dynamic, intelligent chatbot responses, automating cybersecurity support and significantly reducing manual workload.</li>
                </ul>
                <div className="divider-bar"></div>
                <ul className="skills-footer">
                  <li>JavaScript</li>
                  <li>Python</li>
                  <li>Flask</li>
                  <li>HTML</li>
                  <li>CSS</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
