import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HomePage } from "./Pages/HomePage/HomePage";
import { ExperiencePage } from "./Pages/Experience/ExperiencePage";
import { ProjectsPage } from "./Pages/Projects/ProjectsPage";
import { ResumePage } from "./Pages/Resume/ResumePage";
import { MVMNTPage } from "./Pages/ProjectDescriptions/MVMNTPage";
import { BogglePage } from "./Pages/ProjectDescriptions/BogglePage";
import { TrackrPage } from "./Pages/ProjectDescriptions/TrackrPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

const Main = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/experience" element={<ExperiencePage/>}/>
          <Route path="/projects" element={<ProjectsPage/>}/>
          <Route path="/resume" element={<ResumePage/>}/>
          <Route path="/mvmnt" element={<MVMNTPage/>}/>
          <Route path="/boggle" element={<BogglePage/>}/>
          <Route path="/trackr" element={<TrackrPage/>}/>
        </Routes>
      </Router>
    </div>
  );
};

root.render(<Main />);
