import React from "react";
import { Header } from "../../Components/Header";
import "../../Styling/HomePage.css";
import headshotImage from "../../Images/pfp.png";
import githubImage from "../../Images/github.png";
import linkedinImage from "../../Images/linkedin.png";
import { Footer } from "../../Components/Footer";

export function HomePage() {
  return (
    <div>
      <Header />
      <div className="homepage">
        {/* Homepage container */}
        <div className="homepage-container">
          {/* Image & Link container */}
          <div className="image-link-container">
            <img className="pfp-image" src={headshotImage} alt="pfp" />
            <div className="link-container">
              <p className="email">jamiston11@gmail.com</p>
              <a
                href="https://github.com/jamesfisher19"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="github-logo" src={githubImage} alt="GitHub" />
              </a>
              <a
                href="https://www.linkedin.com/in/jamesfisher20/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="linkedin-logo"
                  src={linkedinImage}
                  alt="LinkedIn"
                />
              </a>
            </div>
          </div>
          {/* Bio container */}
          <div className="bio-container">
            <h1 className="name-header">James Fisher</h1>
            <h2 className="title-header">Software Engineer</h2>
            <p className="bio">
            I am a Full Stack Engineer with a degree from Howard University. 
            My passion lies in software engineering, 
            where I dedicate myself to designing and developing 
            solutions that elevate user experiences!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
