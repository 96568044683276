import React from "react";
import { Header } from "../../Components/Header";
import MVMNTdemo from "../../Images/DEMO.mp4";
import "../../Styling/ProjectDescriptions/MVMNTPage.css";
import { Footer } from "../../Components/Footer";

export function MVMNTPage() {
  function handleVideoClick() {
    const videoElement = document.getElementById("mvmnt-demo");
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    }
  }

  return (
    <div>
      <Header />
      <div className="mvmntpage">
        <div className="mvmntpage-container">
          <h1 className="page-title-mvmnt">MVMNT</h1>
          <div className="mvmnt-container">
            <div className="technologies-used">Technologies Used</div>
            <ul className="mvmnt-skills-list">
              <li>Javascript</li>
              <li>HTML</li>
              <li>CSS</li>
              <li>AWS</li>
              <li>GraphQL</li>
              <li>Google Maps API</li>
            </ul>
          </div>
          <div className="mvmnt-description">
            <h1 className="project-overview">Project Overview</h1>
            <p className="mvmnt-description-text">
              As the Frontend Lead of my senior capstone project team of five, I was
              proud to be a part of the creation of MVMNT, a project that won
              first place in Howard University’s Engineering & Architecture
              Capstone competition. This innovative social platform aims to
              connect minority-owned businesses to customers who appreciate
              their unique offerings. Our user-friendly interface, designed
              using Javascript, HTML, CSS, AWS, GraphQL, and Google Maps API,
              caters to the needs of both businesses and customers. Businesses
              gain a platform for promoting themselves, receiving reviews, and
              tracking engagement. Customers are empowered to discover new
              businesses, post reviews, and personalize their profiles. Despite
              MVMNT being in its MVP state, its achievement in the competition
              demonstrates the potential it holds for revolutionizing the way
              minority-owned businesses connect with their clientele.
            </p>
          </div>
          <div className="mvmnt-video">
            <video
              id="mvmnt-demo"
              src={MVMNTdemo}
              controls
              onClick={handleVideoClick}
            ></video>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
