import React from "react";
import { Header } from "../../Components/Header";
import "../../Styling/ProjectDescriptions/TrackrPage.css";
import { Footer } from "../../Components/Footer";

export function TrackrPage() {


  return (
    <div>
      <Header />
      <div className="trackrpage">
        <div className="trackrpage-container">
          <h1 className="page-title-trackr">Trackr</h1>
          <div className="trackr-project-container">
            <div className="trackr-technologies-used">Technologies Used</div>
            <ul className="trackr-project-skills-list">
                <li>Python</li>
                <li>Django</li>
                <li>JavaScript</li>
                <li>HTML/CSS</li>
                <li>Bootstrap</li>
            </ul>
          </div>
          <div className="trackr-link-container">
            <h1 className="trackr-link-header">Link</h1>
            <p className="trackr-link">
              <a
                href="https://mytrackr-23a28849caa7.herokuapp.com/home/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Trackr
              </a>
            </p>
          </div>
          <div className="trackr-description">
            <h1 className="trackr-overview">Project Overview</h1>
            <p className="trackr-description-text">
                Trackr is a straightforward tool for managing job applications. 
                Input details like company name, role, location, link, deadline, 
                and application status for each job. Easily filter applications 
                by company name or application status.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
