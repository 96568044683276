import React, { useState, useEffect } from "react";
import { Header } from "../../Components/Header";
import "../../Styling/ProjectDescriptions/BogglePage.css";
import { Footer } from "../../Components/Footer";
import leftImage from "../../Images/Slideshow/left.png";
import rightImage from "../../Images/Slideshow/right.png";

export function BogglePage() {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    importAll(
      require.context(
        "../../Images/BoggleScreenshots",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
  }, []);

  const importAll = (r) => {
    const images = r.keys().map(r);
    setSlides(images);
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  return (
    <div>
      <Header />
      <div className="bogglepage">
        <div className="bogglepage-container">
          <h1 className="page-title-boggle">Boggle Solver</h1>
          <div className="boggle-project-container">
            <div className="boggle-technologies-used">Technologies Used</div>
            <ul className="boggle-project-skills-list">
              <li>Javascript</li>
              <li>HTML</li>
              <li>CSS</li>
              <li>React</li>
              <li>Firebase</li>
            </ul>
          </div>
          <div className="boggle-description">
            <h1 className="boggle-overview">Project Overview</h1>
            <p className="boggle-description-text">
              Based on the famous Boggle game, Boggle Solver is a simple game I developed during
              my software engineering course at Howard University, using
              JavaScript, HTML, CSS, React, and Firebase. This interactive web
              application allows you to login with your email and customize game
              modes with features such as word length, starting letter, and grid
              size.
            </p>
          </div>
          <div className="boggle-link-container">
            <h1 className="boggle-link-header">Link</h1>
            <p className="boggle-link">
              <a
                href="https://swe-boggle-project-3402c.web.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Boggle Solver
              </a>
            </p>
          </div>
          <div className="boggle-slides">
            <img
              className="boggle-images"
              src={slides[currentSlide]}
              alt="Slide"
            />
            <img
              src={leftImage}
              className="boggle-left-click"
              onClick={handlePreviousSlide}
              alt="leftImage"
            />
            <img
              src={rightImage}
              className="boggle-right-click"
              onClick={handleNextSlide}
              alt="rightImage"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
