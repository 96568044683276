import React from "react";
import "../Styling/Header.css";
import logo from "../Images/logo.png";

export function Header() {
  return (
    <div className="header">
      <div className="options-container">
        <a className="logo" href="/">
          <img className="home-logo" src={logo} alt="Home" />
        </a>
        <div className="options">
          <a className="experience" href="/experience">
            Experience
          </a>
          <a className="projects" href="projects">
            Projects
          </a>
          <a className="resume" href="resume">
            Resume
          </a>
        </div>
      </div>
    </div>
  );
}
