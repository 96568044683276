import React from "react";
import "../Styling/Footer.css";

export function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-border"></div>
      <div className="footer"></div>
    </div>
  );
}
