import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { saveAs } from "file-saver";
import { Header } from "../../Components/Header";
import "../../Styling/ResumePage.css";
import yourResume from "../../Components/Resume - James Fisher.pdf";
import downloadButton from "../../Images/download.png";
import { Footer } from "../../Components/Footer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export function ResumePage() {
  // eslint-disable-next-line
  const [numPages, setNumPages] = useState(1);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  // eslint-disable-next-line
  const handleDownload = () => {
    saveAs(yourResume, "Resume - James Fisher.pdf");
  };

  return (
    <div>
      <Header />
      <div className="resumepage">
        <div className="resumepage-container">
          <div className="pdf-options">
            <a className="open-pdf" href={yourResume} target="_blank" rel="noopener noreferrer">
              Open
            </a>
            <div className="download-container">
              <a href={yourResume} download="Resume - James Fisher.pdf">
                <img className="download-button" src={downloadButton} alt="Download" />
              </a>
            </div>
          </div>
          <div className="pdf-container">
            <Document
              file={yourResume}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              <Page
                key="page_1"
                pageNumber={1}
                width={1000}
                height={921}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
