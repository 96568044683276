import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../../Components/Header";
import "../../Styling/ProjectsPage.css";
import { Footer } from "../../Components/Footer";

export function ProjectsPage() {
  return (
    <div>
      <Header />
      <div className="projectspage">
        <div className="projectspage-container">
          <h1 className="page-title-projects">Projects</h1>
          {/* MVMNT */}
          <Link to="/mvmnt" className="project-container">
            <div className="project-header">MVMNT</div>
            <ul className="project-skills-list">
              <li>Javascript</li>
              <li>HTML</li>
              <li>CSS</li>
              <li>AWS</li>
              <li>React</li>
              <li>GraphQL</li>
            </ul>
          </Link>
          {/* TRACKR */}
          <Link to="/trackr" className="trackr-container">
            <div className="trackr-header">Trackr</div>
            <ul className="trackr-skills-list">
              <li>Python</li>
              <li>Django</li>
              <li>JavaScript</li>
              <li>HTML/CSS</li>
              <li>Bootstrap</li>
            </ul>
          </Link>
          {/* BOGGLE */}
          <Link to="/boggle" className="boggle-container">
            <div className="boggle-header">Boggle Solver</div>
            <ul className="boggle-skills-list">
              <li>Javascript</li>
              <li>HTML</li>
              <li>CSS</li>
              <li>React</li>
              <li>Firebase</li>
            </ul>
          </Link>
          {/* PORTFOLIO */}
          <Link to="/" className="portfolio-container">
            <div className="portfolio-header">Personal Site</div>
            <ul className="portfolio-skills-list">
              <li>Javascript</li>
              <li>HTML</li>
              <li>CSS</li>
              <li>React</li>
            </ul>
          </Link>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
